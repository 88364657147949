// import firebase from "firebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import {getAuth} from "firebase/auth";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAc1R79g3_bXpYTkFQMxTSFdEa0n984PYc",
    // authDomain: "challenge-bcf4d.firebaseapp.com",
    authDomain: "weiwhite.com",
    projectId: "challenge-bcf4d",
    storageBucket: "challenge-bcf4d.appspot.com",
    messagingSenderId: "653629186550",
    appId: "1:653629186550:web:fc30fae89ff0d49981a7d7",
    measurementId: "G-0FY3TTS563"
  };

  const firebaseApp=firebase.initializeApp(firebaseConfig);

  const db=firebaseApp.firestore();
  const auth=firebase.auth();
  const storage=firebase.storage();

  const uiConfig=({
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      
    ],
    callbacks:{
      signInSuccessWithAuthResult: () => false,
    },
  });

  export {db,auth,storage,uiConfig};
  export const authentication=getAuth(firebaseApp);
  //do this in terminal
  // npm run build
  // serve -s build
  // Set-ExecutionPolicy -Scope Process -ExecutionPolicy Bypass
  //cd functions
  // firebase emulators:start 
  //firebase deploy --only hosting

  //firebase login
  //firebase init

  // "npm --prefix \"$RESOURCE_DIR\" run lint"
  //above line in firebase.json function predeploy
  //firebase functions:config:set stripe.token=<key>
  //firebase functions:config:get

  
  