// import axios from "axios";

export const isInCart=(product, cartItems)=>{
    return cartItems.find(item=>item.id === product.id);
}

// const API ='http://localhost:5001/challenge-bcf4d/us-central1/api';
const API ='https://us-central1-challenge-bcf4d.cloudfunctions.net/api';

export async function fetchFromAPI(endpoint, opts) {
    const { method, body } = { method: 'POST', body: null, ...opts };
    const res = await fetch(`${API}/${endpoint}`, {
      method,
      ...(body && { body: JSON.stringify(body) }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    });

      return res.json();
  }