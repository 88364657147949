import React from 'react';
import "./Subtotal.css";
import * as CurrencyFormat from 'react-currency-format';
import { useStateValue } from './StateProvider';
import { getBasketTotal } from './reducer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import StripeCheckout from './stripe-checkout/stripe-checkout';

function Subtotal() {
   const history=useHistory();
   const [{basket,user},dispatch]=useStateValue();
   const goPayment=((e)=>{
      if (!user){
         history.push('/login')
      }
      // else {
      //    history.push('/')
      // }
   });

  return <div className='subtotal'>
      <CurrencyFormat
       renderText={(value)=>(
          <>
           <p>
            Subtotal ({basket.length} items): <strong>{value}</strong>
           </p>
           <small className='subtotal_gift'>
              <input type='checkbox' />
              This order contains a gift
           </small>
          </>
      )}
      decimalScale={2}
      value={getBasketTotal(basket)}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"} 
     />
     <StripeCheckout />
   
  </div>;
}

export default Subtotal;
