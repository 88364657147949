import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import './ProductImages.css';

function ProductImages(props) {
  const [Image, setImage] = useState([])
    //  console.log(props.detail.image)

  useEffect(()=>{
    if (props.detail.image && props.detail.image.length>0) {
        let image=[];

        props.detail.image && props.detail.image.map(item => {
            image.push({
                original: `${item}`,
                thumbnail:`${item}`
            })
        })
        setImage(image)
    }
  }, [props.detail])

  return (

    <div className='product__image' style={{margin:'auto'}}>
      <ImageGallery items={Image} thumbnailPosition='bottom' />
    </div>
  )
}

export default ProductImages