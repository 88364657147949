import React from 'react'
import './ShipPolicy.css';

const ShipPolicy = () => {
  return (
    <div className='ship_policy'>   
    <h1> Shipping, Return and Privacy Policies</h1>
        <br />
        <h2>Shipping Policy</h2>
        <p>
         Enjoy free delivery within the US. For international shipping, please contact weiwhite.info@gmail.com.
        </p>
        <span>
          All orders are processed within 8-10 business days (excluding weekends and holidays) after receiving your order and payment confirmation email. You will receive another notification when your order has shipped. 
        </span>
        <span>
          If you haven’t received your order within 10 business days of receiving your shipping confirmation email, please contact us at weiwhite.info@gmail.com with your name and order number, and we will look into it for you.
        </span>
        <br />
        <h2>Return & Refund</h2>
        <span>
           We accept returns up to 14 days after delivery, if the item is unused and in its original condition, and we will refund the full order amount minus the shipping costs for the return. 
        </span>
        <span>
           In the event that your order arrives damaged in any way, please email us as soon as possible at weiwhite.info@gmail.com with your order number and a photo of the item’s condition. We address these on a case-by-case basis but will try our best to work towards a satisfactory solution. If you have any further questions, please don't hesitate to contact us at weiwhite.info@gmail.com.
        </span>
        <br />
        <h2>Privacy Policy</h2>
        <span>
            Weiwhite Art respect your privacy and are committed to protecting it through our compliance with this policy. We collect several types of information from and about users of our Website, including information by which you may be personally identified, such as name, postal address, e-mail address, telephone number, or any other identifier by which you may be contacted online. We collect this information directly from you when you register your account for service delivery, or automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies.
        </span>
        <br />
        <span>
            Weiwhite Art collects personal information to deliver, improve, personalize and market our products and services. With Facebook login, user can remove weiwhite.com and requests that we delete your data. Users can do this by going to Facebook profile and clicking the Send Request button on the Settings and Privacy - Settings - Apps and Websites page. We acknowledge receiving a user data deletion request through email with a confirmation number
        </span>

    </div>
  )
}

export default ShipPolicy;
