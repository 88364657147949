import React from 'react';
import './Header.css';
import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { Facebook, Instagram, Pinterest, Twitter } from "@material-ui/icons";
import {Link} from "react-router-dom";
import {useStateValue} from './StateProvider';
import {auth} from "./firebase";


function Header() {
    const [{basket,user},dispatch]=useStateValue();
    const handleAuthentication=()=>{
        if (user){
          auth.signOut();
        }
    }

  return ( <div className='header'>
            <Link to='/'>
            <img className="header_logo" 
            // src="http://pngimg.com/uploads/amazon/amazon_PNG11.png"
            src="../Logo design 7.png"
            />
            </Link>
            <Link to='/'>
            <div className='header_home'>
              Home
            </div>
            </Link>
            <div className='header_search'>
                <input 
                className='header_searchInput' type='text' />
                <SearchIcon className='header_searchIcon' />
                
            </div>

            <div className="social_icon">
                  <a href="https://www.facebook.com/Weiwhiteart-101782775786741">
                  <Facebook />
                  </a>
                  <Instagram />
                  <Twitter />
                  <a href="https://www.pinterest.com/WeiWhiteArt/_saved/">
                  <Pinterest />
                  </a>
                </div>

            <div className='header_nav'>
                <Link to={!user && '/login'}>
                <div onClick={handleAuthentication} className='header_option'>
                   <span className='header_optionLineOne'>Hello {!user ? 'Guest' : user.displayName}</span>
                   
                   <span className='header_optionLineTwo'>{user ? 'Sign out' : 'Sign In'}</span>
                </div>
                </Link>

                <Link to='/orders'>
                 <div className='header_option'>
                   <span className='header_optionLineOne'>Return</span>
                   <span className='header_optionLineTwo'>& Orders</span>
                 </div>
                </Link>

               <Link to='/checkout'>
                <div className="header_optionBasket">
                    <ShoppingBasketIcon />
                    <span className="header_optionLineTwo header_basketCount">
                     {basket?.length}
                    </span>
                </div>
                </Link>
            </div>
  </div>
  );
}


export default Header;

