import React from 'react';
import styled from "styled-components";
import Product from "./Product";
import { productItems } from "./Data";
import Select from 'react-select';
import './Products.css';


const Products = () => {
    const [categ, setCategory] = React.useState();
  
    const categoryOptions = productItems.map((p) => p.categ)
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((categ) => ({ label: categ, value: categ }))
  
    let productsFinal={};
    if (!categ) {
      productsFinal=productItems;
    }
    else {productsFinal=productItems.filter((item) => item.categ === categ.value);
  }
  return (
    <div className="prod_list">
    <div className='filter_container'>
      <div className='filter_text'>Filter by Category:</div>
      <div className='filter_selt' style={{boxShadow: '0 5px 5px #939596',borderRadius: '10px!'}}>
      <Select value={categ} onChange={setCategory} options={categoryOptions} isClearable/>
      </div>
    </div>
    <div className='prod_item'>
    {productsFinal.map(item =>(
        <Product  item={item} key={item.id} />
    ))}
    </div>
  </div>
  );
};

export default Products;