import React from 'react';
import "./Product.css";
import { useStateValue } from './StateProvider';
import {Link} from "react-router-dom";

function Product({item}) {

   const [{basket},dispatch]=useStateValue();

   let minPrice=item.variants?.map((p) => p.price)
             .sort((a,b)=>a-b)[0]

  //  const addToBasket=() =>{
  //    //dispatch the item into the data layer
  //     dispatch ({
  //       type:'ADD_TO_BASKET',
  //       item: {
  //         id: item.id, 
  //         title: item.title,
  //         image: item.image,
  //         price: item.price,
  //       },
  //     });
  //  };
  
  return (
  <div className='product'>
    {/* <a href="https://www.etsy.com/shop/weiwhiteart"> */}
    <Link to={`/product/${item.id}`}>
    <img style={{minHeight:'250px', width:'300px'}} src={item.image[0]} alt="" />
    {/* </a> */}
    </Link>
     <div className='product_info'>
      {/* <Link to={`/product/${item.id}`}> */}
      <p className='product_title'>{item.title}</p>
      
      <p className='product_price'>
            <small>$</small>
            <strong>{minPrice} +</strong>
      </p>
        {/* <div className='product_rating'>
            {Array(rating).fill().map((_, i) =>(<p></p>))} 
        </div> */}
      {/* <button onClick={addToBasket}> Add to Basket</button> */}
     </div>
    
  </div>
  );

}

export default Product;
