// import { Button,Descriptions } from 'antd';
import React, {useEffect, useState} from 'react';
// import styled from "styled-components";
import { useStateValue } from '../StateProvider';
import './ProductInfo.css';
import Select from 'react-select';
import {Link} from "react-router-dom";
// import { useAlert } from 'react-alert';

function ProductInfo(props) {
    const [Product, setProduct] = useState({})
    const [{basket},dispatch]=useStateValue();

    useEffect(()=>{
        setProduct(props.detail)
      }, [props.detail])
      let minPrice=Product.variants?.map((p) => p.price)
      .sort((a,b)=>a-b)[0]

const [material, setMaterial] = React.useState();
const [size, setSize] = React.useState();
// const [price, setPrice] = React.useState();

const materialOptions = Product.variants?.map((p) => p.material)
.filter((v, i, a) => a.indexOf(v) === i)
.map((material) => ({ label: material, value: material }))

const sizeOptions = Product.variants?.filter((p) => material && p.material === material.value)
.map((p) => p.size)
.filter((v, i, a) => a.indexOf(v) === i)
.map((size) => ({ label: size, value: size }));

const priceOptions = Product.variants?.filter((p) => size && p.size === size.value && material && p.material=== material.value)
.map((p) => p.price)
.filter((v, i, a) => a.indexOf(v) === i)
.map((price) => ({ label: price, value: price }));

let priceFinal={};
if (priceOptions?.length === 1){priceFinal=priceOptions[0].value}
else {priceFinal=minPrice + "+"}

useEffect(()=>{
  setSize(null)
 },[material])

const addToBasket=(e) =>{
  if (!material?.value){
    e.preventDefault();
     alert('Please select a material')
   } else if(!size?.value){
    e.preventDefault();
    alert('please select a size')}
     else {
  //dispatch the item into the data layer
     dispatch ({
      type:'ADD_TO_BASKET',
      item: {
        id: props.detail.id, 
        title: props.detail.title,
        image: props.detail.image,
        price: priceFinal,
        material: material?.value,
        size: size?.value
      },
   })}
};
console.log(!material?.value)

return (
   <div className='prod__info'>
        
    <h1>{Product.title}</h1>
    <h5 className='prod__desc'>Description</h5>
    <p className='prod__text'>{Product.desc}</p>
    <p>Free shipping in US</p>
    <p className='prod__price'>
            <small>$</small>
            <strong> {priceFinal}</strong>
    </p>
    <br />
    {/* selection option start */}
      <p className='prod__desc'>Material</p>
      <div style={{boxShadow: '0 5px 5px #939596',cursor:'pointer',borderRadius: '10px!'}}>
      <Select value={material} onChange={setMaterial} options={materialOptions} isClearable/>
      </div>

      <p className='prod__desc'>Size</p>
      <div style={{boxShadow: '0 5px 5px #939596',cursor:'pointer',borderRadius: '10px!'}}>
      <Select value={size} onChange={setSize} options={sizeOptions} isClearable/>
    </div>
    <br />
    <br />
    <Link to='/checkout'>
    <div className='button__cart'>    
      <button className='prod__button' onClick={addToBasket}>
            Add to basket
      </button>
    </div>
    </Link>

</div>

)
}

export default ProductInfo