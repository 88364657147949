
import React, { useState,useEffect } from 'react';
import firebase from 'firebase/compat/app';
// import {signInWithPopup,FacebookAuthProvider} from 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import './Login.css';
// import {Link} from "react-router-dom";
import { authentication, uiConfig} from './firebase';
// import { useStateValue } from './StateProvider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Login() {
  const history = useHistory ();
  const [isSignedIn, setIsSignedIn] = useState(false);

  // const [{basket,user},dispatch]=useStateValue();
  
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  // const signInWithFacebook=()=>{
  //   const provider=new FacebookAuthProvider(); 
  //   signInWithPopup(authentication, provider)
  //   .then((re)=>{
  //    console.log(re);
  //   })
  //   .catch((err)=>{
  //    console.log(err.message);
  //   })
  //  }
  
  if (!isSignedIn) {
    return (
      <div className='login'>
        <div className='left_login'>
        {/* <h1>Wei White Art</h1> */}
        <h1>Please sign-in:</h1>
        <br />
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        {/* <br /> */}
        {/* <button onClick={signInWithFacebook} style={{width:220,backgroundColor:'#3b5998', color:'white', height:40,cursor:'pointer'}}>Sign in with Facebook</button> */}
        </div>

        <div className='right_login'>
         <h1>Wei White Art</h1>
         <br />
            <img 
            className='login_logo'
            src='../Logo design 7.png' alt='' />
          <br />
          <h4>Under the privacy policy, Weiwhite Art collects only user's email for order and payment confirmation</h4>
        </div>
      </div>
    );
    
  }
  // history.push('/')
  
  return (
    <div className>
       <h1>Wei White Art</h1>
      <p>Hello {firebase.auth().currentUser.displayName} ({firebase.auth().currentUser.email})! You are now signed-in!</p>
      <br /> 
      <button className='sign__button' 
          onClick={() => history.push('/')}>
            Continue Shopping
          </button>
      {/* <button className='sign__button' onClick={() => firebase.auth().signOut()}>Sign-out</button>  */}
     <br />
     {/* <span>{!user.email ?<h3>Please note that user's email login is needed to process checkout</h3>:
            "Welcome to Weiwhite Art"}</span> */}
     <h3>Please note that user's email in each login is needed to process checkout and orders</h3>
       <img 
        className='login_logo'
        src='../Logo design 7.png' alt='' /> 
      </div>
  );
}

export default Login;
