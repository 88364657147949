import React from 'react';
import './CheckoutProduct.css';
import { useStateValue } from './StateProvider';

function CheckoutProduct({id,image,title,price,material,size,hideButton}) {
  const [{basket},dispatch]=useStateValue();
  const removeFromBasket =() =>{
       dispatch({
           type: 'REMOVE_FROM_BASKET',
           id: id,
       })
  }

  return <div className='checkoutProduct'>
      <img className='checkoutProduct_image' src={image} alt="" />

      <div className='checkoutProduct_info'>
         <p className='checkoutProduct_title'>{title}</p>
         <p>{material}</p>
         <p>{size}</p>
         <p className='checkoutProduct_price'>
             <small>$</small>
             <strong>{price}</strong>
         </p>
         {!hideButton && (
          <button onClick={removeFromBasket}>Remove from Basket</button>
          
         )}
      </div>
  </div>;
}

export default CheckoutProduct;
