import React from 'react';
import "./Home.css";
import Products from './Products';

function Home() {
  return (
  <div className='home'>
   <div className='home_container'>
       {/* <a href="https://www.etsy.com/shop/weiwhiteart"> */}
       <img 
       className='home_image'
       src="../banner design pic.png" alt="" />
       {/* </a> */}
       <Products />
   </div>
  </div>
  );
}

export default Home;
