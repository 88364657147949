import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import './Orders.css';
import { useStateValue} from './StateProvider';
import Order from './Order';

function Orders() {
  const [{basket, user}, dispatch]=useStateValue();
  const [orders, setOrders]=useState([]);

  useEffect(()=>
    db
    .collection('customer')
    .doc(user?.email)
    .collection('orders')
    .orderBy('timeStamp','desc')
    .onSnapshot(snapshot =>
      setOrders(snapshot.docs.map(doc=>doc.data()))),[]);

  return (
  <div className='orders'>
     <h1>Your Orders</h1>

     <div className='orders_detail'>
       {orders?.map(order => (
           <Order order={order} />
       ))}
     </div>
  </div>
  );
}

export default Orders;

