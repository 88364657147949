import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './StateProvider';
import reducer,{initialState} from "./reducer";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const  promise=loadStripe('pk_live_51KMbNcJYJAYch09tbg1WippIhWMZt9ugNUSTZTUehxNfzlOreXqUTQK8a4cV54dEOR96vhhMPWsWE54WHZJbQCkQ00isP5MkPw');


ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
    <Elements stripe={promise}>
    <App />
    </Elements>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
