import React, { useEffect, useState } from 'react';
import ProductImages from './ProductImages';
import ProductInfo from './ProductInfo';
import {productItems} from '../Data';
import { useParams } from 'react-router-dom';
import './DetailProductPage.css';

function DetailProductPage() {

    const { id }=useParams();
    const [details, setDetails]=useState([])
    
    useEffect(() => {
        setDetails(productItems.find(item => String(item.id) ===id ))
    
     }, [id]);
        
    return (
      <div className="detailInfo" >
        {/* style={{width:'100%', padding: '3rem 4rem'}} */}
          <div className="detailLeft">
               <ProductImages detail={details} />
           </div>
           <div className="detailRight">
               <ProductInfo  detail={details} />
           </div>
      </div>
      
  )
}


export default DetailProductPage