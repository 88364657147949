import React from 'react';
import CheckoutProduct from './CheckoutProduct';
import './Order.css';
// import moment from 'moment';
// import CurrencyFormat from 'react-currency-format';

function Order({order}) {
  
  return  (
    <div className='order'>
       <h2> Order </h2>
       {/* <p>{moment.unix(order.data.created).format("MMMM Do YYYY, h:mma")}</p> */}
       <p>{order.timeStamp},  Order # {order.orderItems[0].productData.created}</p>
       <h4>Total Price $ {order.amountTotal}</h4>
       <div className='order_info' style={{width:'100%', padding: '1rem 1rem'}}>
         <div className="orderLeft">
           {order.orderItems.map(item=>(
             <CheckoutProduct 
             id={item.productData.id}
             image={item.productData.product.images}
             title={item.productData.product.name}
             price={item.productData.unit_amount/100}
             material={item.productData.product.description}
             hideButton
             />
           ))}
         </div>
         <div className="orderRight">
           <h3>Shipping Info: </h3>
           <h4>{order.shppingInfo.name}</h4>
           <p>{order.shppingInfo.address.line1}</p>
           <p>{order.shppingInfo.address.line2}</p>
           <p>{order.shppingInfo.address.city}, {order.shppingInfo.address.state}, {order.shppingInfo.address.country}, {order.shppingInfo.address.postal_code}</p>
         </div>
       </div>
    </div>
   )
 }
 
 // export default withRouter(Order);
 export default Order;