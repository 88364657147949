import React, { useContext, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useStateValue } from '../StateProvider';
import { fetchFromAPI } from '../helpers';
import "./stripe-checkout.css";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { db } from '../firebase';


const StripeCheckout = () => {
  const history=useHistory();
  const [{basket, user},dispatch]=useStateValue();
  // const [email, setEmail] = useState('');
  const [processing,setProcessing]=useState("");
  const stripe = useStripe();
  console.log(basket)

  const handleGuestCheckout = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const line_items = basket?.map(item => {
      return {
        quantity: 1,
        price_data: {
          currency: 'usd',
          unit_amount: item.price*100, // amount is in cents
          product_data: {
            name: item.title,
            description: item.material + ', ' + item.size,
            images: [item.image[0]],
          },
        },
      }
    });
    const response = await fetchFromAPI('create-checkout-session', {
      body: { line_items, customer_email: user.email },
    });

    const { sessionId } = response;
    const { error } = await stripe.redirectToCheckout({
      sessionId
    })
    // .then(({session})=>{
    //   db
    //   .collection('users')
    //   .doc(user?.uid)
    //   .collection('orders')
    //   .doc(session.id)
    //   .set({
    //       basket: basket,
    //       amount: session.amount_total,
    //   })
    // });
    console.log(sessionId);
    if (error) {
      console.log(error);
    }
  }
  
  const goPayment=(()=>{
    if (!user || !user.email){
       history.push('/login')
    }
 });

  return (
    <form onSubmit={handleGuestCheckout}>
     
      <div className='submit-btn'>
        <button type='submit' className='button is-black nomad-btn submit' onClick={goPayment} >
        <span>{processing ?<p>Processing</p>:
          "Proceed to Checkout"}</span>
        </button>
      </div>
    </form>
  );
}

export default StripeCheckout;