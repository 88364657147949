import React from 'react';
import './Checkout.css'
import CheckoutProduct from './CheckoutProduct';
import { useStateValue } from './StateProvider';
import Subtotal from './Subtotal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Checkout() {
  const history = useHistory ();
  const [{basket, user},dispatch]=useStateValue();
  return (
     <div className="checkout__main">
       <div className="checkout__left">
         <img className="checkout__ad" 
         src="../checkout banner.png"  alt="" />
          <div>
            <h3>Hello, {user?.email}</h3>
            <h2 className='checkout__title'>Your Shopping Basket</h2>
          </div> 
          <div className='Basket__item'>
           { basket.map( item => (
            <CheckoutProduct 
            id={item.id}
            title={item.title}
            image={item.image[0]}
            material={item.material}
            size={item.size}
            price={item.price}
            />
           ))}
          </div>
          <button className='home__button' 
          onClick={() => history.push('/')}>
            Continue Shopping
          </button>
       </div>
       <div className="checkout__right">
        <Subtotal />
       </div> 
     </div>
  );
}

export default Checkout;
