import React, { useContext, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { db } from '../firebase';
import { useStateValue } from '../StateProvider';
import Order from '../Order';


function Success() {
    const [{basket, user}, dispatch]=useStateValue();
    const [orders, setOrders]=useState([]);
  
  //   useEffect(()=>{
  //    if (user) {
  //     db
  //     .collection('users')
  //     .doc(user?.uid)
  //     .collection('orders')
  //     .orderBy('created','desc')
  //     .onSnapshot(snapshot => (
  //         setOrders(snapshot.docs.map(doc => ({
  //             id: doc.id,
  //             data: doc.data()
  //         })))
  //   ))
  // } else {
  //     setOrders([])
  // }
  //   },[user])
  
    return (
    <div className='orders'>
       <h1>Thank You for Your Orders</h1>
       <p>We are currently processing your orders and 
          will send you a payment confirmation email shortly. Visit <Link to='/orders'> Return and Orders </Link> to view your order details
       </p>
       {/* <div className='orders'>
         {orders?.map(order => (
             <Order order={order} />
         ))}
       </div> */}
    </div>
    );
  }
  
  export default withRouter(Success)
  
  


// const Success = ({ history }) => {
//   const { clearCart} = useContext(CartContext);
//   useEffect(clearCart,[]);

//   return (
//       <div className='checkout'>
//         <h1>Thank you for your order</h1>
//         <p>We are currently processing your order and 
//           will send you a confirmation email shortly
//         </p>
//         <div>
//           <button className='button is-black nomad-btn submit' 
//           onClick={() => history.push('/shop')}>
//             Continue Shopping
//           </button>
//         </div>
//       </div>
//   );
// }

// export default withRouter(Success);