import { Facebook, Instagram, MailOutline, Phone, Pinterest, Room, Twitter } from "@material-ui/icons";
import styled from "styled-components";
import {Link} from "react-router-dom";
import './Footer.css';


const Container=styled.div`
  display: flex;
  background: #314c72;
  background: linear-gradient(to left bottom, #eff5f4,#314c72);
`;

const Left=styled.div`
   flex: 1;
   display:flex;
   align-items:center;
   justify-content: center;
   flex-direction: column;
   padding: 20px;
`;

const ContactItem =styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color:white;
`;
const Payment =styled.img`
  display:flex;
  padding-left:120px;
  width: 50%;
`;
const Logo=styled.h1`
  color:white;
`;
const Desc=styled.p`
  margin: 5px 0px;
  color:white;
`;
const SocialContainer=styled.div`
  display: flex;
  align-items:center;
  justify-content: center;

`;
const SocialIcon=styled.div`
   width: 30px;
   height: 30px;
   border-radius: 50%;
   color: white;
   background-color: #${props=>props.color};
   display:flex;
   align-items:center;
   justify-content: center;
   margin-right:20px;
   
`;

const Center=styled.div`
   flex: 1;
   padding: 10px;
`;

const Title=styled.h3`
  margin-bottom: 20px;
  color:white;
`;


const Right=styled.div`
   flex: 1;
   display:flex:
   align-items:center;
   justify-content: center;
   padding: 20px;
`;


const Footer = () => {
  return (
    <Container>
        <Left className="left_footer">
            <Title> Policy & Contact </Title>
            <Link to='/shippolicy'>
             <ContactItem>
                {/* <Room style={{marginRight:"10px"}}/> 1600 Market St. Philadelphia, PA. 19100 */}
                Shipping, Return and Privacy Policy
            </ContactItem>
            </Link>

            <ContactItem>
                <MailOutline style={{marginRight:"5px"}}/>weiwhite.info@gmail.com
            </ContactItem>
            <SocialContainer>
                <SocialIcon color='55ACEE'>
                <a href="https://www.facebook.com/Weiwhiteart-101782775786741">
                    <Facebook />
                </a>
                {/* </SocialIcon>
                <SocialIcon color='E4405F'>
                    <Instagram />
                </SocialIcon>
                <SocialIcon color='3B5999'>
                    <Twitter /> */}
                </SocialIcon>
                <SocialIcon color='E60023'>
                  <a href="https://www.pinterest.com/WeiWhiteArt/_saved/">
                    <Pinterest />
                  </a>
                </SocialIcon>
            </SocialContainer>
        </Left>
        <Center className="center_footer">
        <Link to='/termuse'>
        <Title>
          Terms of Use
        </Title>
        </Link>
         <Desc>© Copyright 2022, Weiwhite.com All Rights Reserved. </Desc>
         <Title> Value </Title>
         <Desc>WeiWhite Art continual support of these charitable groups: </Desc>
         <a href="https://www.stjude.org/">
         <Desc>St.Jude Children's Research Hospital</Desc>
         </a>
         <a href="https://www.sos-childrensvillages.org/">
         <Desc>SOS Children's Villages International</Desc>
         </a>
        </Center>
        {/* <div className="right_footer"> */}
        <Right className="right_footer">
            <Logo>WeiWhite</Logo>
            <Desc>Simplicity, Naturality and Humanity of Art </Desc>
            <br />
            <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" /> 
        </Right>
        {/* </div> */}
    </Container>
  );
};

export default Footer;
