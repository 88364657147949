import React from 'react'
import './TermUse.css';

const TermUse = () => {
  return (
    <div className='term_use'>   
    <h1> Terms of Service Agreements </h1>
        <br />
        <span>
            <h3>Welcome to Weiwhite Art </h3>
            <br />
            By using or accessing this website at any time, it is implied that you agree with the terms and conditions below set by Weiwhite Art. If you do not agree with these terms of use, please do not use this website. Weiwhite Art is taking the liberty to revise these terms of service agreement or terms of use at any time by updating this page, and your use after such change signifies your acceptance of the changed terms. Please check these terms of use periodically for updates.
        </span>
        <br />
        <span>
        <h3>Intellectual property</h3>
         <br />
          All trademarks, service marks, trade names that may appear on the Website are owned by us. Except for the limited use rights granted to you in these Terms of Use, you shall not acquire any right, title or interest in the Website or any Website Content. Any rights not expressly granted in these Terms of Use are expressly reserved.
        </span>

        <span>
        <h3>Disclaimer</h3>
         <br />
         Some materials, unless otherwise noted, were taken from the Internet and are assumed to be in the public domain. In the event that there is still a problem or error with copyrighted material, the break of the copyright is unintentional and noncommercial and the material will be removed immediately upon presented proof.
        </span>
    </div>
  )
}

export default TermUse;
