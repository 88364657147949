export const productItems = [
    {
        id: 1,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20S4%204.jpg?alt=media&token=ff4b6be9-8a9b-49ee-8461-c245aa54cb84",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20S4%206.jpg?alt=media&token=2be8093d-be01-470e-a5e1-ca8429bf5cf8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20S4%202.jpg?alt=media&token=1c1f46b3-8a58-46ca-9212-b364980d17c8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20S4.jpg?alt=media&token=fe7288da-cc64-463a-b9de-9cf024de9eef",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20S4%205.jpg?alt=media&token=dc4cc125-05d6-4875-a316-fc6ea6c976d4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20S4%203.jpg?alt=media&token=8dabbcf6-ab64-4254-8c36-295aed4f0931",
        
        ],
        title: "Abstract Pollock Blue",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock Blue color painting with embeded signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    {
        id: 2,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Circle%20Square%202.jpg?alt=media&token=5a73481f-e16b-4114-a15d-2e4bd6c9ae27",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Circle%20Square.jpg?alt=media&token=1d6cefab-3208-4b6d-9f82-6390127f09fa",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FAbstract%20geometric%20circle.jpg?alt=media&token=cfe76fb2-c915-410e-86b2-506e55dd30f3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Circle%20Square%205.jpg?alt=media&token=8cff9fe9-dcf3-4f83-9b39-08c46aa02554",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Circle%20Square%203.jpg?alt=media&token=0109c4d4-ac53-4d15-9503-50aa35180aab",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Circle%20Square%204.jpg?alt=media&token=2184bb0b-e9e5-4bdb-b7b8-44bf25fc19c8"
        ],
        title: "Abstract Geometric Square",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This abstract geometric square circle painting explores the idea of abstract and beautiful geometric pattern in natural. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame,Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface.",
        variants: [{material: "Stretched Canvas", size: "16 x 16 x 0.75D  inches",  price: 129.50},
                   {material: "Stretched Canvas", size: "20 x 20 x 1.5D  inches",  price: 179.25},
                   {material: "Stretched Canvas", size: "24 x 24 x 1.5D  inches",  price: 231.25},
                   {material: "Stretched Canvas", size: "30 x 30 x 1.5D  inches",  price: 326.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches",  price: 498.50},
                   {material: "Roll Canvas", size: "16 x 16 inches",  price: 62.25},
                   {material: "Roll Canvas", size: "20 x 20 inches",  price: 89.75},
                   {material: "Roll Canvas", size: "24 x 24 inches",  price: 105.75},
                   {material: "Roll Canvas", size: "30 x 30 inches",  price: 192.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 250.75},
                   {material: "Fine Art Print", size: "16 x 16  inches",  price: 49.25},
                   {material: "Fine Art Print", size: "20 x 20 inches",  price: 68.75},
                   {material: "Fine Art Print", size: "24 x 24 inches",  price: 94.75},
                   {material: "Fine Art Print", size: "30 x 30 inches",  price: 136.75},
                   {material: "Fine Art Print", size: "40 x 40 inches",  price: 238.75},
                  ],
    },

    {
        id: 3,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20Computation.jpg?alt=media&token=fa6e3173-45ab-4fcb-a524-7549dab6361e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FAbstract%20geometric_%20Binary%20stock%20vector.jpg?alt=media&token=37b34aa9-1ac4-4401-9589-fe6e496574f6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20Computation2.jpg?alt=media&token=2940f32e-8860-4e71-a153-9d10cbb5195c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20Computation3.jpg?alt=media&token=ccdaf556-66f3-4924-8895-f5b0f1c99bd8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20Computation4.jpg?alt=media&token=9e2f2fb6-3906-49fc-81f6-105f997b94a0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20Computation5.jpg?alt=media&token=0b232422-d77e-4693-ad3e-c0a587d7b682",
        ],
        title: "Abstract Geometric Binary Data",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "Abstract Geometric Binary Stock Vector Data, This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in data science. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame,Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface.",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 208.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 265.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 498.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 82.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 105.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 305.75},
                   {material: "Fine Art Print", size: "30 x 12  inches",  price: 62.25},
                   {material: "Fine Art Print", size: "40 x 16 inches",  price: 98.75},
                   {material: "Fine Art Print", size: "60 x 24 inches",  price: 215.75},
                  ],
    },

    {
        id: 4,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%204.jpg?alt=media&token=dfc56430-3620-4482-a058-475fc7d53a16",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color.jpg?alt=media&token=d6792ccb-0173-4bf7-9f5a-51bdccbf6fa4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%202.jpg?alt=media&token=8b2ce281-68fd-46a1-8b98-89aa964fa162",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%203.jpg?alt=media&token=f9610ee0-86c1-4785-9e78-7a1876690e29",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%205.jpg?alt=media&token=e357220d-ff11-4bbc-be3a-b76b63e72f66",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%206.jpg?alt=media&token=f50ef5d5-7f54-4db2-99bc-ebfb2b579124",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Mix Color",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Mix Color with Embedded Signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 628.25},
                   {material: "Stretched Canvas", size: "28 x 60 x 1.5D  inches", price: 669.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 720.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
     
    {
        id: 5,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Budha%20drawing3.jpg?alt=media&token=43aafc96-e509-4b75-8366-36a03b58000b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBudha%20portrait%20gold%202.jpg?alt=media&token=52c08710-4da7-4017-a1d8-54e07487bd26",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Budha%20drawing.jpg?alt=media&token=3dc62e0f-e31c-43cb-a1e4-f183e645abd8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Budha%20drawing2.jpg?alt=media&token=c0193570-ad67-4206-91ee-c17de9f91dc6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Budha%20drawing4.jpg?alt=media&token=3af7ff7f-65cf-4d1e-b477-091836cbf27d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Budha%20drawing5.jpg?alt=media&token=10fd767f-3345-4162-879c-2de2349665b7",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Budha%20drawing6.jpg?alt=media&token=2f47fa30-af2c-4f87-ba9a-ac8ce57a0be0"],
        title: "Budha Meditation",
        categ:"Minimalist",
        price:149.99,
        desc: "Minimalist Budha Meditation Painting Large Canvas| Art Print, Home Wall Decor, Simplicity Art Collectibles.Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "20W x 16H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "24W x 20H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "30W x 24H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 30H x 1.5D  inches",  price: 287.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 396.25},
                   {material: "Fine Art Print", size: "10W x 8H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "20W x 16H inches",  price: 49.75},
                  ],
    },
    {
        id: 6,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20pattern2.jpg?alt=media&token=eec45246-cc7c-4dc7-b807-c7190806d4ab",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20pattern.jpg?alt=media&token=17cc0718-5dd4-41a4-863b-25ceb7751ce8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FStock%20binary%20vector%202.jpg?alt=media&token=284762ff-a7cb-45bd-a071-0911d1bf1026",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20pattern3.jpg?alt=media&token=5822b6b5-7092-424b-ae65-ee897e0f8385",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20pattern5.jpg?alt=media&token=06acafdf-2b23-42fe-bd26-9762f0fa505e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20pattern6.jpg?alt=media&token=5674197f-f99d-4b84-894d-688ddaca632c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20pattern4.jpg?alt=media&token=8517e818-f092-4f9f-96b1-cde0b0ed99ad"
        ],
        title: "Abstract Geometric Binary Data",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "Abstract Geometric Binary Radial Vector Data Pattern,This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in data science. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame,Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface.",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 208.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 265.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 498.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 82.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 105.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 305.75},
                   {material: "Fine Art Print", size: "30 x 12  inches",  price: 62.25},
                   {material: "Fine Art Print", size: "40 x 16 inches",  price: 98.75},
                   {material: "Fine Art Print", size: "60 x 24 inches",  price: 215.75},
                  ],
    },
    {
        id: 7,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FPond_Koi%20Fish.jpg?alt=media&token=1d25462a-4297-46c3-84fc-0a40c3864728",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Koi%20Fish2.png?alt=media&token=a74fc588-9ab6-46bd-a02d-762244f52d47",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Koi%20Fish3.png?alt=media&token=5a9a4b35-4d1b-4ff9-8c41-f34767d7a279",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Koi%20Fish4.png?alt=media&token=1d199b1e-101d-4516-970c-42dae51d4c4f",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Koi%20Fish5.png?alt=media&token=7f5d8e59-16ed-4566-a207-9d73e7bce2e9",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Koi%20Fish7.png?alt=media&token=bd1df02f-d8e5-4f29-a23b-70328b6e5a33",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Koi Fish Lilypad",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16W x 12H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "20W x 16H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "24W x 20H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "30W x 24H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "10W x 8H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "16W x 12H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "20W x 16H inches",  price: 49.75},
                  ],
    },

    {
        id: 8,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Duckling8.png?alt=media&token=0d3dc12b-c2ce-4c15-aa1d-48b61232c77b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Duckling.png?alt=media&token=3576c0f3-9ef8-44a9-9904-509cad41ef42",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Duckling2.png?alt=media&token=5d94e51a-6267-4a35-86ef-6b7501e4f432",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Duckling3.png?alt=media&token=c1d7dd56-1bdd-43c4-9882-803b02e0caf8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Duckling6.png?alt=media&token=0ae2fb7f-63d7-41b0-9142-4927b6dd97cb",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Bath Duckling",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 12H x 0.75D  inches",  price: 75.25},
                   {material: "Stretched Canvas", size: "16W x 16H x 0.75D  inches",  price: 102.25},
                   {material: "Stretched Canvas", size: "20W x 20H x 0.75D  inches",  price: 146.25},
                   {material: "Stretched Canvas", size: "24W x 24H x 1.5D  inches",  price: 208.25},
                   {material: "Stretched Canvas", size: "30W x 30H x 1.5D  inches", price: 281.25},
                   {material: "Fine Art Print", size: "8W x 8H inches",  price: 17.75},
                   {material: "Fine Art Print", size: "12W x 12H inches",  price: 29.50},
                   {material: "Fine Art Print", size: "16W x 16H inches",  price: 41.75},
                  ],
    },
    {
        id: 9,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Ocean%20Fish2.png?alt=media&token=bdf65a68-d2c3-497b-bc68-2dbc9d811d34", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FFish_Swim_H.jpg?alt=media&token=d9370225-68e7-4df8-b936-6d1600e77081",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Ocean%20Fish10.png?alt=media&token=2477db0a-d8f6-453a-bbfc-e6e8b6675122",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Ocean%20Fish12.png?alt=media&token=8fb7f78e-a334-4ac2-b50c-3e7576db90d5",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Ocean%20Fish3.png?alt=media&token=07e440d0-e97b-4f1b-858a-20b8dc0f0949",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Ocean%20Fish6.png?alt=media&token=993e0718-7a48-4964-a429-3e5efa9f27a2",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMockup_edit11.png?alt=media&token=e6b12cb6-49a5-45ff-ac7c-0bb90ad63933",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Ocean Fish",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 92.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 130.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 196.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 255.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price: 20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
                  ],
    },
    {
        id: 10,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20Pollock%20Purple.jpg?alt=media&token=170f55e7-a187-47f9-b54b-9a9d80656978",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20Pollock%20Purple%206.jpg?alt=media&token=dae78e53-cdfb-4f54-9bbf-43df3de071e0", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20Pollock%20Purple%205.jpg?alt=media&token=2d19418a-8a47-40ff-b5fd-7a9b64c307b3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20Pollock%20Purple%204.jpg?alt=media&token=6b55b251-8052-47de-9c7e-ef96ebe6610b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20Pollock%20Purple%203.jpg?alt=media&token=940a345c-b76f-4612-9de9-958788b0b071",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20Pollock%20Purple%202.jpg?alt=media&token=4a5fdc25-1e7a-4c62-9e20-8ada0888aba8",
        ],
        title: "Abstract Pollock Purple",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock Purple color painting with embeded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    {
        id: 11,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20SeaBottom%203.jpg?alt=media&token=c6cc209d-2795-4017-bac6-c3fd81c725c6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20SeaBottom.jpg?alt=media&token=ec6c904c-401a-4889-9834-196c5bec67a3", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20SeaBottom%206.jpg?alt=media&token=4ebc77c4-c4bf-4932-931c-9a0ed322d39a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20SeaBottom%205.jpg?alt=media&token=cdb2b283-56b5-49fe-9349-8114ed8b74fd",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20SeaBottom%204.jpg?alt=media&token=6b79c8cd-ec0c-4afb-88e4-70982dd5c9af",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20SeaBottom%202.jpg?alt=media&token=f5c8fff6-b3f9-4775-8276-e3d509bb17a3",
        ],
        title: "Abstract Pollock Blue",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock Blue color painting with embeded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    
    {
        id: 12,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FHunt_Lion.jpg?alt=media&token=e902a832-8375-4943-851c-37058aa233df",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FLion%20canvas%20MK.png?alt=media&token=1e75ec0f-631a-43cc-ac1e-68510ed04b85",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FLion-Poster-Mockup-1.jpg?alt=media&token=60180497-e23f-4ca8-9ebf-4d2dcfadc102",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FLion-Poster-Mockup.png?alt=media&token=c4603d62-880f-4876-a990-d768f7aff8be",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FLion-poster-MK.png?alt=media&token=cc5fe9a8-2517-4483-8806-b51295e77112",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FLion-poster-MK2.png?alt=media&token=d898c520-9de2-4a87-a0aa-ef28503b4983",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Hunt Lion",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 13,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FEarly%20Spring%202.jpg?alt=media&token=e5530f20-b8de-4bbb-9b06-9e5a76ac06a2",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK%20Early%20Spring.jpg?alt=media&token=96ec8de3-2a30-4de2-aac5-befa30b0a9b9",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Spring%20View2.jpg?alt=media&token=4dc51d3c-5037-4ac8-afc3-77fd96d7a430",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Spring%20View3.jpg?alt=media&token=961fed32-9f48-4d48-9d67-c73cf4f28371",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK%20Spring%20View.jpg?alt=media&token=cdf0b21a-24ba-4bd1-8eb8-09e128858664",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Early%20Spring8.jpg?alt=media&token=fccc97c8-1503-4e1e-8b87-dd40f242fd2a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Spring Wood",
        categ:"Impressionist",
        price:149.99,
        desc: "Wood in Spring painting with embedded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 14,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FHigh%20Land.jpg?alt=media&token=d36f6da9-673b-46d8-ab4a-60712044535f",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland2.png?alt=media&token=818f358e-54c4-46b5-8329-92589a8d968d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland3.png?alt=media&token=ee6b89b0-1dfb-4b27-93f5-c8ca145349ce",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland4.png?alt=media&token=26509cab-e2b9-4854-96fa-188cc34204e6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland6.png?alt=media&token=3e81139a-19bd-4615-a9a9-ef2aed706c52",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland7.png?alt=media&token=0003536a-32a9-40e9-884f-984a11dce276",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "View Highland",
        categ:"Impressionist",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    
    {
        id: 15,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FSea_Before_Storm2.png?alt=media&token=597ac342-2717-4484-a600-1ae2c85a2b15",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat.png?alt=media&token=d60da356-eea0-47b4-9f88-e26957af6d91",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat2.png?alt=media&token=bd3fea53-8fde-4fc9-a2d5-f076838df058",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat3.png?alt=media&token=820e221f-4873-4919-98f5-2782388e80ee",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat4.png?alt=media&token=a32aa56f-6cbe-4080-a4b1-129d49eeb7ce",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat6.png?alt=media&token=db06da38-4fe2-48d4-a924-d7c16bf0b7d6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Ocean Before Storm",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    
    {
        id: 16,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FPond_Birch%20Tree.jpg?alt=media&token=49df5066-426c-4641-b45e-68d1a47dd1c6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Birch%20Tree.png?alt=media&token=1acc4250-f5c4-44ca-bc71-3ef5762e672b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Birch%20Tree2.png?alt=media&token=e5394a4f-9830-4bc8-bac4-cfc4c63c9f2b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Birch%20Tree3.png?alt=media&token=3b05ddb7-a7d7-45db-9161-7ac6b213a0f5",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Birch%20Tree6.png?alt=media&token=33fd78b7-2a3f-4883-ba7f-8a0d98ae3321",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Birch%20Tree7.png?alt=media&token=26561959-72a1-4c4b-9d66-a16f6b8e0f40",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Pond Birch Tree",
        categ:"Impressionist",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16W x 12H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "20W x 16H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "24W x 20H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "30W x 24H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "10W x 8H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "16W x 12H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "20W x 16H inches",  price: 49.75},
                  ],
    },
    {
        id: 17,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Whale%20Sunset.png?alt=media&token=11138e82-a639-40cd-90d9-fbf64593a790",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Whale%20Sunset8.png?alt=media&token=bd18da93-cbbf-4e3e-8499-5e321051accb",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FOcean_Whale.jpg?alt=media&token=a608d70a-0f99-496e-b1a6-bc7e1a995e7c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Whale%20Sunset2.png?alt=media&token=de1b38e3-1949-42da-80e2-bf7eb1305c19",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Whale%20Sunset3.png?alt=media&token=6fbca44b-6a0f-4e18-b129-02ddaf810615",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Whale%20Sunset5.png?alt=media&token=5f29b8a1-10bf-4ad5-9af4-f46ab492ecb4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Ocean Whale",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 92.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 130.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 196.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 255.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price:20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
                  ],
    },

    {
        id: 18,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Lotus%20Pond8.png?alt=media&token=e69bd785-0f36-44c7-ba09-9b6eb67d21c0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Lotus%20Pond6.png?alt=media&token=ae302395-4048-4bea-a277-60ed28fbb001",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FPond_Lily%20Pad_Lotus.jpg?alt=media&token=327f5f56-4d97-41d0-a219-a4a02ce02400",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Lotus%20Pond.png?alt=media&token=fae607d9-7188-4fe9-95d4-dfaad89670e3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Lotus%20Pond2.png?alt=media&token=ad2b509c-5736-4ea6-8562-0a1c3bd2191a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Lotus%20Pond5.png?alt=media&token=0e93a35e-c264-4b8a-9329-3a153f1b53b6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Pond Lilypad Lotus",
        categ:"Impressionist",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16W x 12H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "20W x 16H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "24W x 20H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "30W x 24H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "10W x 8H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "16W x 12H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "20W x 16H inches",  price: 49.75},
                  ],
    },
    {
        id: 19,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Horse.png?alt=media&token=1f5a89fb-bb3d-41ea-875c-c2352b15875b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Horse2.png?alt=media&token=e11ed6c6-a43c-471f-8645-b616f7d1ef83",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Horse3.png?alt=media&token=ab69d44b-eb2d-44a9-ab9e-5ce49f4598a4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Horse4.png?alt=media&token=3bcace9a-fc32-404a-b867-82a5ba91c94c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Horse5.png?alt=media&token=0ac2155f-33e3-4021-aea9-077e22a52c7e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FHorse_Mounted%20Depth2.png?alt=media&token=08c4b5ee-6dbb-459e-b21a-da01c6661170",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Gaze Horse",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16W x 12H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "20W x 16H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "24W x 20H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "30W x 24H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "10W x 8H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "16W x 12H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "20W x 16H inches",  price: 49.75},
                  ],
    }, 

    {
        id: 20,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2Fsea%20urchin.jpg?alt=media&token=0e6f8708-d23a-4fd8-b7d5-720577ada662",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Sea Urchin",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16W x 12H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "20W x 16H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "24W x 20H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "30W x 24H x 1.5D  inches",  price: 221.25},
                   {material: "Fine Art Print", size: "10W x 8H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "16W x 12H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "20W x 16H inches",  price: 49.75},
                  ],
    },
    
    {
        id: 21,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s2.jpg?alt=media&token=5417477b-e855-4ace-8b69-71b890611d20",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s2%206.jpg?alt=media&token=3a3df06c-f16e-4745-b335-9d98c491711a", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s2%205.jpg?alt=media&token=d146a683-4111-473a-8b4e-37494a28e36d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s2%204.jpg?alt=media&token=738ba271-8e2c-4eab-ab91-3ec9a9e41a99",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s2%203.jpg?alt=media&token=6b8e41c2-4a16-4876-935e-eec041406d30",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s2%202.jpg?alt=media&token=54ee8798-7615-45d3-a891-b47c042d464b",
        ],
        title: "Abstract Pollock Blue",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock Blue color painting with embeded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    {
        id: 22,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FPond_Swan.jpg?alt=media&token=26f271cf-1801-4a9a-8b1b-fd11671546da",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Swan.png?alt=media&token=c66f42ee-1c79-4ea3-b2ef-cef96b9a4387",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Swan10.png?alt=media&token=7d6bc303-42d5-458a-9e61-fabc4a7fbea3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Swan11.png?alt=media&token=f0721e27-5a25-4e6b-9610-ea64b59e1ef6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Swan4.png?alt=media&token=201e70a1-430f-4a34-806f-6327856a99bf",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Swan9.png?alt=media&token=1858ceee-6527-4e64-b16b-7899bd078dd1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Pond Swan",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    
    {
        id: 23,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FSea_Wave_Surf.jpg?alt=media&token=6fea5244-f770-40c9-87ab-610d4aa57d32",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Surf.png?alt=media&token=f367ca57-9aec-4e0c-9614-d1f720f46167",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Surf4.png?alt=media&token=cfbf55a1-5915-48d4-9c2e-062651f2d952",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Surf6.png?alt=media&token=6d61a961-51ff-4a6d-a88c-12a09ff315df",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Surf7.png?alt=media&token=b9a9953c-73b6-4144-b694-c0fd82972ff1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Surf8.png?alt=media&token=b58a69ad-0e90-40c2-84a1-f704fe0d5937",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Sea Wave Surf",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 24,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FBeach3.jpg?alt=media&token=629a6556-f0ee-4a5b-b846-bacefe013a95",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_BeachHouse.jpg?alt=media&token=d4b94e29-bf9f-41fb-9a88-50447a3690d8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_BeachHouse2.jpg?alt=media&token=38201ec9-b7e9-488f-bbc9-c1d45f1aef44",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_BeachHouse3.jpg?alt=media&token=87d0ac6d-f9d2-4b15-a45d-a438ef5980a0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_BeachHouse4.jpg?alt=media&token=b1bfa167-e6e9-4c15-8a5b-76286fa951a0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_BeachHouse6.jpg?alt=media&token=573e681a-0347-4684-9d83-898c1653a8ae",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Beach View",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
        },
    {
        id: 25,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_BeachWater4.jpg?alt=media&token=9bba36dc-d6f4-4723-a4c6-ee3f7bb21cf3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FBeach%20Water3.png?alt=media&token=33058c53-f366-4c61-a94c-3e8bc18990d6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_BeachWater.jpg?alt=media&token=e2573906-b8cb-4984-ad2a-666aff0c0332",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_BeachWater2.jpg?alt=media&token=76076b9b-e7c9-409b-9516-107f7e349dcc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_BeachWater3.jpg?alt=media&token=197b6057-489c-44d7-9cb7-ebb1d1665b0d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_BeachWater7.jpg?alt=media&token=059a1712-541b-4653-81d5-aece3cdfe5df",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Beach Water",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },

    {
        id: 26,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s3.jpg?alt=media&token=39373c43-cefe-4fc9-945e-dabbb6abcf55",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s3%206.jpg?alt=media&token=b7f519de-ad44-4c66-99ab-51edc33d01e9", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s3%205.jpg?alt=media&token=df9d93e7-d67e-4ff2-9c71-25a8301fc13c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s3%204.jpg?alt=media&token=cbbb20fa-8bda-4dbb-afa4-adf27e3dd3cd",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s3%203.jpg?alt=media&token=3f209346-696c-497d-a67c-de9499c45416",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_SeaBottom%20s3%202.jpg?alt=media&token=73685e0d-c942-4b1f-a63d-46e8c59b4549",
        ],
        title: "Abstract Pollock Blue",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock Blue color painting with embeded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    {
        id: 27,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FBeach%20Shell2.jpg?alt=media&token=0d7cf82f-1000-4253-95ad-b93234245c8d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_SeaUrchin.png?alt=media&token=c5abc8d5-a51a-4593-a314-9b7f2c7f9b44",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_SeaUrchin2.png?alt=media&token=395e6ed4-19d8-4c48-94a3-3fcc79f8b832",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_SeaUrchin4.png?alt=media&token=96d62281-f7dc-4614-88c6-0948ffad48fe",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_SeaUrchin6.png?alt=media&token=64560f6e-f88e-40d5-933b-1972514f2468",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_SeaUrchin7.png?alt=media&token=5dbd7a49-0ba8-42bb-986f-e390af4ce9f2",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Beach Shell",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 12H x 0.75D  inches",  price: 75.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 112.25},
                   {material: "Stretched Canvas", size: "20W x 20H x 0.75D  inches",  price: 136.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 30H x 1.5D  inches", price: 266.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 12H inches",  price: 28.50},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 28,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FSea_Shell2.jpg?alt=media&token=4b183d5f-e0f0-4773-a92f-82830befcc66",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Seashell2.png?alt=media&token=0a1ee0df-60ed-4bb9-98c6-2e86d9129d4f",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Seashell3.png?alt=media&token=81e7c549-9eaa-49e6-b10d-7fe08d2e6eb1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Seashell4.png?alt=media&token=c7e1cbfc-0cf4-490f-8dba-d2e264f6b42c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Seashell5.png?alt=media&token=109885cd-fa29-415d-9e53-19e414d4d3e1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Seashell9.png?alt=media&token=f974a485-1b9a-4dc6-a831-1a3e2ec4a8d3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Beach Sea Snail",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 12H x 0.75D  inches",  price: 75.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 112.25},
                   {material: "Stretched Canvas", size: "20W x 20H x 0.75D  inches",  price: 136.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 30H x 1.5D  inches", price: 266.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 12H inches",  price: 28.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 29,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach.jpg?alt=media&token=c19d8693-07f1-4143-b379-8ed1301418f2",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FSea_Dawn2.jpg?alt=media&token=eec57731-7ae0-40e5-850c-cd0eaef136c8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach2.jpg?alt=media&token=d4c7d6d8-38eb-496c-816a-fa45b2483d27",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach5.jpg?alt=media&token=fb0e2630-703e-479a-8967-1dc6adff6da6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach6.jpg?alt=media&token=dbcc35c2-685f-46a9-aa0f-bf94935ac5a2",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach8.jpg?alt=media&token=27562745-acee-4bec-85ec-fb42a75f8fbc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Beach Dawn",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 30,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Wetland2.png?alt=media&token=3b27168a-890f-4800-9651-dbc50444ebe1", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWetland%203.jpg?alt=media&token=14bd5a67-9105-4717-8137-0ed91a60c838",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Wetland11.png?alt=media&token=b1c760a4-27c4-4644-85c6-883916353397",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Wetland13.png?alt=media&token=9db2957f-fc49-455f-bac7-3251efe45998",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Wetland6.png?alt=media&token=797b9306-6fcd-497e-9976-c955d5816cd8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Wetland9.png?alt=media&token=0f6b271a-1a73-4409-8983-18e5397d73c5",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Bay Wetland",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 92.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 130.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 196.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 255.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price: 20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
                  ],
    },
    {
        id: 31,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FBay_sail_Edit2.jpg?alt=media&token=e1992f87-2df1-494a-a8cb-0d10f93fab62",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat%20Bay.png?alt=media&token=392a0df2-deff-4bde-8bc8-408cb8fe4b7c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat%20Bay2.png?alt=media&token=d0aeea07-0980-43fd-a054-657b0a28bd53",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat%20Bay5.png?alt=media&token=a4c92059-b068-4d66-8045-b8115a68b0a8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat%20Bay6.png?alt=media&token=513e664a-6cd2-48ac-acc2-25543f3d46df",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Sailboat%20Bay7.png?alt=media&token=aa336cb3-e35b-4f4d-aa0c-1bf68fbcd645",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Bay Sail",
        categ:"Seascape",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 32,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2Fwhale.jpg?alt=media&token=bf0d8399-10e0-4853-a3f8-314ee6e23eab",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_DWhale2.png?alt=media&token=8b26b730-f2a2-475a-9e8c-d1ef876d5125",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_DWhale3.png?alt=media&token=e949908b-c7b8-43e3-8d15-d127fda98002",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_DWhale4.png?alt=media&token=3841c3b8-55e8-46cb-b8fc-e62e37e8ce4d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_DWhale7.png?alt=media&token=698e4faf-ff5c-4eab-b5dd-6a1affd55568",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_DWhale8.png?alt=media&token=2478bff3-ec2d-460b-bc9d-d1059ab31cfc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Dive Whale",
        categ:"Animal",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 33,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FEarly%20Spring.png?alt=media&token=c9c3950f-ed3a-46ee-ad43-989b1ac1dfe1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK%20big%20painting3.jpg?alt=media&token=1067c6ad-c81e-42b1-b0b5-c255f4b8a6cb",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK%20big%20painting4.jpg?alt=media&token=29b1164a-089c-4af4-a375-e9feeab75a84",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Early%20Spring.jpg?alt=media&token=a43641c4-a487-4e03-8b69-13624ee2d8cd",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Early%20Spring6.jpg?alt=media&token=93a1afdc-02e4-47d9-8ca0-5bf4073cbca8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Early%20Spring7.jpg?alt=media&token=75792b43-ad2b-4d3c-a7bb-606de811de94",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Early Spring",
        categ:"Minimalist",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 34,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Beach%20Wave%20Simple.jpg?alt=media&token=c7c8252e-d652-4a2e-b2ef-a6e617d386df",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach%20Wave%20Simple2.jpg?alt=media&token=00ec1ca5-e925-47da-bd34-95980d6c4641",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach%20Wave%20Simple3.jpg?alt=media&token=f568f336-e8d7-4367-9148-a36ecf1ea8fc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach%20Wave%20Simple4.jpg?alt=media&token=402600e0-120a-4c8e-a650-1c48e1fdf8bc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMk_Beach%20Wave%20Simple5.jpg?alt=media&token=d84eb8ee-18fb-4dbe-89e6-e5ee844ae7ef",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FBeach%20Wave%20Simple.jpg?alt=media&token=eb6de4db-df1a-420e-9a17-0c3f44d2de52",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Beach Wave",
        categ:"Minimalist",
        price:149.99,
        desc: "Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 35,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20light%20blue6.jpg?alt=media&token=bae2dc42-04eb-4139-b0e8-da5e08a15833",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20light%20blue.jpg?alt=media&token=9789b0f7-df91-495f-ad44-7cba9c1e607d", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20light%20blue2.jpg?alt=media&token=fff2b7a1-483b-4818-af04-69011f8e7a41",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20light%20blue3.jpg?alt=media&token=3460bb70-c9b2-47ea-ab79-678eb57f9ce9",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20light%20blue4.jpg?alt=media&token=eabd8dc9-98cb-4355-91f3-25b7fdd2a19c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20light%20blue5.jpg?alt=media&token=9041a042-4179-497c-9607-55d9dc445138",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Pollock Blue",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock blue color painting with embeded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    {
        id: 36,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red.jpg?alt=media&token=6c62411f-b37a-4bdd-85ff-8c751ca0a739",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red2.jpg?alt=media&token=c02a4d47-fe14-48f1-b5d6-38b83a078e93",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red3.jpg?alt=media&token=732cf36a-c45e-44ae-b105-3f62ef3456a9",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red4.jpg?alt=media&token=8a1ba05f-1f07-4f26-a20b-cc322404d3e3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red5.jpg?alt=media&token=f64c156d-3750-42d6-a563-10ade0e94a0e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red6.jpg?alt=media&token=eb241d35-81db-43bf-98b2-4195ec5e3395",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Abstract Pollock Red",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock red color painting with embeded signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    {
        id: 37,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Green6.jpg?alt=media&token=39e103b6-61a3-4465-90ce-3211d1360dcb",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Green.jpg?alt=media&token=69443e7a-c0bc-46ae-bce3-2e5509c7c91e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Green2.jpg?alt=media&token=85c8bafc-f733-429f-a443-49d4018514d8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Green3.jpg?alt=media&token=21e20ebd-a6b7-463f-9c08-ce5fb9739522",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Green4.jpg?alt=media&token=3b9be110-ca73-4e86-84a7-b9fff3aa6cf7",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Green5.jpg?alt=media&token=b2a2021e-0a29-49f2-83a4-331384923537",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Pollock Green",
        categ:"Abstract Color Mix",
        price:149.99,
        desc: "Abstract Pollock green color painting with embeded signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    
    {
        id: 38,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland%205.jpg?alt=media&token=22c06b1c-1d21-41de-8632-fc130aaafa74",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FEtsy%20highland.png?alt=media&token=e6daac41-3769-4e18-b348-23428a522206",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland%202.jpg?alt=media&token=f197f99e-df91-4cf4-a324-26d219fdacfb",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland%203.jpg?alt=media&token=be696f73-0243-4e0c-a626-bbe5ea5478d0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland%204.jpg?alt=media&token=7d78b102-03d8-4597-a52d-41a46a967a50",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland%206.jpg?alt=media&token=8789ea66-567d-41c9-84df-98e74447a3cc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland%207.jpg?alt=media&token=0f955ca7-2fcb-4c28-b2f6-ae4ab08d19b6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland%208.jpg?alt=media&token=ae9582a1-f060-47c3-a144-0c1c6679f642",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Highland.jpg?alt=media&token=109ba42e-95de-44d1-b044-0a78839e5aa0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Highland",
        categ:"Impressionist",
        price: 149.99,
        desc: "Highland painting with embedded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 92.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 130.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 196.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 255.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price: 20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
       ],
    },
    
    {
        id: 39,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FEtsy%20Water%20Lily.jpg?alt=media&token=83e49499-af8d-4a97-8c09-fd5948e7f872",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Water%20Lily%207.jpg?alt=media&token=f4958f0d-ca38-4c2d-8a2e-97fe2678d61c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Water%20Lily%206.jpg?alt=media&token=45ae67b2-4845-4194-a69a-aee284a1061b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Water%20Lily%205.jpg?alt=media&token=d78a91b2-3b2d-4f15-8ef3-e51615a6dba3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Water%20Lily.jpg?alt=media&token=15132249-2ae2-4acf-b02e-a13e2f831284",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Water%20Lily%202.jpg?alt=media&token=e475e079-ee3f-4fab-af7b-df286e87accc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Water%20Lily%203.jpg?alt=media&token=60c1aae0-53d6-423c-ace8-ed4d41c21f96",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Water%20Lily%204.jpg?alt=media&token=d8147f63-3ee6-4740-b914-543fec684a32",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"],
        title: "Water Lily",
        categ:"Impressionist",
        price: 149.99,
        desc: "Water lily painting with embedded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    
    {
        id: 40,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Moonlight%204.jpg?alt=media&token=2a90cd90-8ab1-4e99-8143-c02b3692124a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2Fetsy%20pond%20moon%20light.jpg?alt=media&token=e967309b-2195-43c9-9314-f68202650168",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Moonlight%202.jpg?alt=media&token=8cc287df-5943-44a3-a55b-47067b7a500e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Moonlight%203.jpg?alt=media&token=cbad7c0b-c250-4f89-9e5e-f6e717a4c1a1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Moonlight%205.jpg?alt=media&token=ece879be-9019-44f4-b51b-586ad7bb2076",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Moonlight%206.jpg?alt=media&token=e78f08d1-9d5b-474a-9397-ae4226aca6e7",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Moonlight%207.jpg?alt=media&token=aa3ade49-7ffb-499b-aaea-34b584135843",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Moonlight%208.jpg?alt=media&token=199de8c5-df1c-4b9a-b764-6d357848271b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
       ],
        title: "Moon Light",
        categ:"Minimalist",
        price: 149.99,
        desc: "Moon Light on a lake painting with embedded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12w x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16w x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20w x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24w x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30w x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    
    {
        id: 41,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue7.jpg?alt=media&token=bcd67525-113e-4f05-8f7c-26700bd3f4e3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue.jpg?alt=media&token=67a71886-ea18-4eb8-a47d-100481f2cde9",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue2.jpg?alt=media&token=ae66e1cf-2307-4ad3-8a3f-8570358c2646",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue3.jpg?alt=media&token=62877fbb-d8c1-4d7a-88bb-39c23f9fbf35",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue4.jpg?alt=media&token=6035168b-78e7-40f2-b8d8-5f8af82f6a51",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue5.jpg?alt=media&token=69b03430-ec9c-4184-94a4-f268e04622c5",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue6.jpg?alt=media&token=3622de41-6f31-40d3-802a-c0575e2b55a1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Dark%20Blue8.jpg?alt=media&token=576938f7-2c8f-4839-b7a8-fec4848b6dad",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Pollock Mix",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Pollock Mix Color with Embedded Signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    
    {
        id: 42,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue3.jpg?alt=media&token=beb2555e-793f-438b-9896-49550c5ea39b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue.png?alt=media&token=f427c72f-82d3-4671-8e18-36db9f3ab475",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue2.jpg?alt=media&token=289d7761-4012-4bbc-9732-28564418d7e7",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue4.jpg?alt=media&token=b8726d6a-1e40-4073-92d3-51dc36412c81",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue5.jpg?alt=media&token=cda1c50c-e7ed-4c6e-a8ff-4c589674e736",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue6.jpg?alt=media&token=760242a6-920e-4e3d-8ea2-6592b33451ad",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue7.jpg?alt=media&token=978917c5-980c-4040-bd03-05bc4e2668fd",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Mix%20Blue9.jpg?alt=media&token=78ced586-0e09-4bab-9a1f-b87b1f1ef42c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
         ],
        title: "Abstract Pollock Mix",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Pollock Mix Color with Embedded Signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },
    
    {
        id: 43,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix4%203.jpg?alt=media&token=bb6888c5-57bd-4a26-bd94-8c09bc652539",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix4%204.jpg?alt=media&token=5b2477ec-046b-4261-b2bd-0c152639162d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix4.jpg?alt=media&token=aed75430-7a2d-4cf5-8203-acb009cc5c17",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix4%206.jpg?alt=media&token=6029bab1-c83e-4687-aaf4-a73b7003d93d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix4%205.jpg?alt=media&token=0e5c5bda-969c-4843-aee7-d17958dbb1ed",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix4%202.jpg?alt=media&token=2145db11-e769-444a-9ea7-eeb26a670032",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Mix Color",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Mix Color with Embedded Signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "24 x 16 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "30 x 20 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "40 x 30 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "48 x 36 x 1.5D  inches",  price: 628.25},
                   {material: "Stretched Canvas", size: "60 x 28 x 1.5D  inches", price: 669.25},
                   {material: "Stretched Canvas", size: "60 x 40 x 1.5D  inches", price: 720.25},
                   {material: "Roll Canvas", size: "24 x 16  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "30 x 20 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "40 x 30 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "48 x 36 inches",  price: 335.75},
                   {material: "Roll Canvas", size: "60 x 28 inches",  price: 369.75},
                   {material: "Roll Canvas", size: "60 x 40 inches",  price: 460.75},
                  ],
    },
    {
        id: 44,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FWetland%20Sunset.JPG?alt=media&token=abe35564-1534-4ad4-b23d-70e6d5436d12",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK_Sunset%20Wetland_Wendy.jpg?alt=media&token=f7fb89a0-0982-4e5b-9818-8648ec814fa6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK6_Sunset%20Wetland_Wendy.jpg?alt=media&token=0f0eff24-5cfe-47e7-9b32-3d75b5878b62",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK5_Sunset%20Wetland_Wendy.jpg?alt=media&token=a5f03567-1d33-4607-acfa-b89fd9987f92",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK4_Sunset%20Wetland_Wendy.jpg?alt=media&token=69b36ebd-af83-413b-8243-2a0b17612e45",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK3_Sunset%20Wetland_Wendy.jpg?alt=media&token=d7951d57-1422-4d11-9bde-1ddf5cb876dd",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK2_Sunset%20Wetland_Wendy.jpg?alt=media&token=3f66e53f-e635-47e7-9965-fbc4ba0d0ba1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "WetLand Sunset by WG",
        categ:"Impressionist",
        price: 149.99,
        desc: "An artwork by artist Wendy Gual. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 92.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 130.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 196.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 255.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price: 20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
                  ],
    },
    
    {
        id: 45,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FFlower%20Vase.JPG?alt=media&token=b3442474-61c2-4e7a-a597-fc439a4adfbc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK2_Flower%20Vase_Wendy.jpg?alt=media&token=9e88032f-d06b-42d2-9ec2-2a0880a38dbc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK3_Flower%20Vase_Wendy.jpg?alt=media&token=84fbeeb2-8f84-446a-921c-9320bdb07f2a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK4_Flower%20Vase_Wendy.jpg?alt=media&token=fa2f5ae1-0e16-4305-a7c6-ef741f535374",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK5_Flower%20Vase_Wendy.jpg?alt=media&token=6ce7b53c-9e14-400d-9ae7-6f857efd8f0b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK6_Flower%20Vase_Wendy.jpg?alt=media&token=f85f2f40-c4ea-4fbd-9415-7ba7a0353dfe",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK_Flower%20Vase_Wendy.jpg?alt=media&token=1609a11d-97f9-4277-818a-4ef7761451b4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Flowers in Vase by WG",
        categ:"Impressionist",
        price: 149.99,
        desc: "An artwork by artist Wendy Gual. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "12W x 12H x 0.75D  inches",  price: 75.25},
                   {material: "Stretched Canvas", size: "16W x 16H x 0.75D  inches",  price: 102.25},
                   {material: "Stretched Canvas", size: "20W x 20H x 0.75D  inches",  price: 146.25},
                   {material: "Stretched Canvas", size: "24W x 24H x 1.5D  inches",  price: 208.25},
                   {material: "Stretched Canvas", size: "30W x 30H x 1.5D  inches", price: 281.25},
                   {material: "Fine Art Print", size: "8W x 8H inches",  price: 17.25},
                   {material: "Fine Art Print", size: "12W x 12H inches",  price: 29.50},
                   {material: "Fine Art Print", size: "16W x 16H inches",  price: 41.75},
                  ],
    },
    {
        id: 46,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWater%20lily%20in%20rain%20edit%204.JPG?alt=media&token=01055481-a8c7-471d-8abc-759bfa8bfb86",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FRain%20Water%20Lily%20MK2.jpg?alt=media&token=dcb6743e-3836-46e5-b1d9-a16b88335da4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FRain%20Water%20Lily%20MK3.jpg?alt=media&token=467e9ef9-9fb5-4b78-8615-d1af107fc476",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FRain%20Water%20Lily%20MK4.jpg?alt=media&token=29153c9e-e80c-466b-97fa-5873f9670f63",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FRain%20Water%20Lily%20MK5.jpg?alt=media&token=c1218b94-be8c-4916-a07a-b09bd964e43e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FRain%20Water%20Lily%20MK6.jpg?alt=media&token=8eb2af89-04ff-4f11-bc89-32bd30460c96",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FRain%20Water%20Lily%20MK7.jpg?alt=media&token=f1be684e-5455-4128-9b29-675f85ed9694",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Rain",
        categ:"Impressionist",
        price: 149.99,
        desc: "Water lily in rain painting. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 186.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 241.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 395.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },

    {
        id: 47,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FTrain.JPG?alt=media&token=d65ae3b5-dfe0-491a-a50f-d3013a4e0407",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK_Train_Wendy.jpg?alt=media&token=fd5d484f-fda2-4785-98b3-4245513716d5",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK6_Train_Wendy.jpg?alt=media&token=13c57f5f-8160-4ee9-b74b-5f4f589387eb",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK5_Train_Wendy.jpg?alt=media&token=7ed88add-7a17-440f-a5b0-cb34d08284a7",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK4_Train_Wendy.jpg?alt=media&token=b8a3daf6-e015-45ee-a4d1-a66ac979a00a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK3_Train_Wendy.jpg?alt=media&token=9e0c5883-7683-4c19-816e-168c46d2fcb4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/WendyG%2FMK2_Train_Wendy.jpg?alt=media&token=c7e31011-f044-4e85-bd42-d216d447c03c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Steam Train by WG",
        categ:"Impressionist",
        price: 149.99,
        desc: "An artwork by artist Wendy Gual. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 166.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 221.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 326.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },

    {
        id: 48,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWind%20Flower.JPG?alt=media&token=7fabb337-c69c-47f6-9fbe-b28f40ce4fbb",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWind%20Flower%20MK.jpg?alt=media&token=37afdf42-ae94-46c1-9a75-dd258a71ad0e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWind%20Flower%20MK2.jpg?alt=media&token=6e109af2-9e78-450c-b2e7-b637487652fe",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWind%20Flower%20MK3.jpg?alt=media&token=5a9dd24b-8a0e-424d-8d88-4f057355326f",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWind%20Flower%20MK4.jpg?alt=media&token=aa1778e6-1ef6-462e-b318-6c1a3bd32894",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWind%20Flower%20MK5.jpg?alt=media&token=a41b2475-7d0e-458b-bed0-e7f8b67707a8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FWind%20Flower%20MK6.jpg?alt=media&token=d61708f9-3714-4815-84a0-db44eb8a1d85",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Wind",
        categ:"Impressionist",
        price: 149.99,
        desc: "Flower in wind painting. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "12W x 16H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "16W x 20H x 0.75D  inches",  price: 126.25},
                   {material: "Stretched Canvas", size: "20W x 24H x 1.5D  inches",  price: 186.25},
                   {material: "Stretched Canvas", size: "24W x 24H x 1.5D  inches",  price: 216.25},
                   {material: "Stretched Canvas", size: "24W x 30H x 1.5D  inches",  price: 241.25},
                   {material: "Stretched Canvas", size: "30W x 40H x 1.5D  inches", price: 395.25},
                   {material: "Fine Art Print", size: "8W x 10H inches",  price: 19.25},
                   {material: "Fine Art Print", size: "12W x 16H inches",  price: 29.75},
                   {material: "Fine Art Print", size: "16W x 20H inches",  price: 49.75},
                  ],
    },
    {
        id: 49,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Flower%20Vase%202_Wendy.jpg?alt=media&token=62d4c3c9-58b2-4341-9e54-a0d24807c06b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FFlower%20Vase%202.JPG?alt=media&token=bf3b231d-e761-4298-a93a-f0004d796927",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK5_Flower%20Vase%202_Wendy.jpg?alt=media&token=8d469826-860d-4f71-81b4-df5dab82338b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK4_Flower%20Vase%202_Wendy.jpg?alt=media&token=82e76fed-db3d-4510-9d2d-05ad6bef05c0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK3_Flower%20Vase%202_Wendy.jpg?alt=media&token=acce803e-7c14-463f-a4b4-b64d20edaf14",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK2_Flower%20Vase%202_Wendy.jpg?alt=media&token=0fedd001-a34f-4787-97df-3b23fa859402",
        ],
        title: "Flowers in Vase by WG",
        categ:"Impressionist",
        price: 149.99,
        desc: "An artwork by artist Wendy Gual. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "12W x 12H x 0.75D  inches",  price: 75.25},
                   {material: "Stretched Canvas", size: "16W x 16H x 0.75D  inches",  price: 102.25},
                   {material: "Stretched Canvas", size: "20W x 20H x 0.75D  inches",  price: 146.25},
                   {material: "Stretched Canvas", size: "24W x 24H x 1.5D  inches",  price: 208.25},
                   {material: "Stretched Canvas", size: "30W x 30H x 1.5D  inches", price: 281.25},
                   {material: "Fine Art Print", size: "8W x 8H inches",  price: 17.25},
                   {material: "Fine Art Print", size: "12W x 12H inches",  price: 29.50},
                   {material: "Fine Art Print", size: "16W x 16H inches",  price: 41.75},
                  ],
    },
    {
        id: 50,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FApril%20Cherry%20MK3.jpg?alt=media&token=74ebdb8e-f463-48bf-a539-cc8956ba0c25",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FApril.jpg?alt=media&token=ff931949-01f7-40fb-bef5-8c0b6f20efe8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FApril%20Cherry%20MK.jpg?alt=media&token=cc5fb355-ddc9-4727-a53e-30d03a6c0305",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FApril%20Cherry%20MK2.jpg?alt=media&token=71b99c87-8321-4a95-be0d-52eb3ecafcba",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FApril%20Cherry%20MK4.jpg?alt=media&token=335f6d46-f900-4fb6-be1b-7d6953293522",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FApril%20Cherry%20MK5.jpg?alt=media&token=7ff11985-9d50-4ee9-ace9-99a7b8399666",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FApril%20Cherry%20MK6.jpg?alt=media&token=ce23c8e0-b601-4abf-ab4a-2e2b4021db82",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "April",
        categ:"Impressionist",
        price: 149.99,
        desc: "Flower blossom in April painting with embedded signature. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 92.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 140.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 226.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 295.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 395.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price: 20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
       ],
    },
    
    {
        id: 51,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_Mar.jpg?alt=media&token=70f893bb-11c8-4675-9a16-0ba6cfe01ca5",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMarch%20Minimalist.JPG?alt=media&token=94d7a724-0980-41a8-be8d-b028d8839f5a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_Mar%205.jpg?alt=media&token=0339e4f9-3b75-44f1-b454-8e529ef56b0f", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_Mar%204.jpg?alt=media&token=325c5b79-4bda-4e1d-b8c5-d7516ce4e887",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_Mar%203.jpg?alt=media&token=1bcb9ee8-04aa-4816-b337-14e5d1d36417",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_Mar%202.jpg?alt=media&token=60b01466-0dae-4d25-89fe-62ad45e9cfba",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Minimalist - March",
        categ:"Minimalist",
        price:149.99,
        desc: "The month oMarch - The misty drizzling days of the year. Minimalist style landscape painting. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 140.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 236.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 305.25},
                   {material: "Stretched Canvas", size: "40W x 20H x 1.5D  inches",  price: 335.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 386.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price: 20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
                  ],
    },

    {
        id: 52,
        image: [
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FWetland%20Bay.jpg?alt=media&token=0721a598-9211-49fa-a5f0-feaf21538fac", 
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_wl.jpg?alt=media&token=9883cd9d-540d-4a0c-ac9a-818ea22e9a6c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_wl%206.jpg?alt=media&token=79c22f5d-f8be-4d60-ad9f-4b73b47a4c54",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_wl%205.jpg?alt=media&token=cc5452c7-1d2d-414c-aecd-90297d2049ea",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_wl%204.jpg?alt=media&token=ff6c3f39-d586-4cf0-950d-0c8bc19540e0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_wl%203.jpg?alt=media&token=9eaf46fe-f722-4488-ac1b-de98e9d0517e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Minimal_wl%202.jpg?alt=media&token=c7ec6b8c-9f72-4f95-93ad-64b3f9bdcc9e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Minimalist - Wetland",
        categ:"Minimalist",
        price:149.99,
        desc: "Wetland - Minimalist style landscape painting. Strethched Canvas: Premium, high quality wood stretcher bars, dried to reduce warping and cracking for canvas framing. Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface. W = Width, H=Height, D=Depth",
        variants: [{material: "Stretched Canvas", size: "18W x 12H x 0.75D  inches",  price: 98.25},
                   {material: "Stretched Canvas", size: "24W x 16H x 0.75D  inches",  price: 140.75},
                   {material: "Stretched Canvas", size: "30W x 20H x 1.5D  inches",  price: 236.25},
                   {material: "Stretched Canvas", size: "36W x 24H x 1.5D  inches",  price: 305.25},
                   {material: "Stretched Canvas", size: "40W x 20H x 1.5D  inches",  price: 335.25},
                   {material: "Stretched Canvas", size: "40W x 30H x 1.5D  inches", price: 386.25},
                   {material: "Fine Art Print", size: "12W x 8H inches",  price: 20.75},
                   {material: "Fine Art Print", size: "18W x 12H inches",  price: 34.25},
                   {material: "Fine Art Print", size: "24W x 16H inches",  price: 62.25},
                  ],
    },

    {
        id: 53,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red%20Blue2.jpg?alt=media&token=cadbb366-f288-4639-a444-371e2270eb87",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FMK_Abstr%20Pollock%20Red%20Blue.jpg?alt=media&token=9c72f2c5-b331-45b1-a9cf-c319d586f24d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Pollock Mix",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Pollock Mix Color with Embedded Signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "40 x 40 x 1.5D  inches", price: 539.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 608.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 680.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "40 x 40 inches",  price: 305.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },

    {
        id: 54,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color2%205.jpg?alt=media&token=80ec9ad4-f2d0-461b-9281-15500c3430ee",
            "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color2.jpg?alt=media&token=9b7af780-4357-4a1e-b440-b7af5b7bb4de",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color2%206.jpg?alt=media&token=765d7c01-799b-4e9e-8da8-2b511343e41a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color2%204.jpg?alt=media&token=194313c7-d321-42b7-9d02-3e5f3ffa5387",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color2%203.jpg?alt=media&token=e739459f-ef37-4c70-99d3-08bc616fc9db",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color2%202.jpg?alt=media&token=712acb9d-5fc5-4a7e-9919-f02e2f61cb6c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color.jpg?alt=media&token=d6792ccb-0173-4bf7-9f5a-51bdccbf6fa4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Mix Color",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Mix Color with Embedded Signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "16 x 24 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "20 x 30 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "30 x 40 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "36 x 48 x 1.5D  inches",  price: 628.25},
                   {material: "Stretched Canvas", size: "28 x 60 x 1.5D  inches", price: 669.25},
                   {material: "Stretched Canvas", size: "40 x 60 x 1.5D  inches", price: 720.25},
                   {material: "Roll Canvas", size: "16 x 24  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "20 x 30 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "30 x 40 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "60 x 28 inches",  price: 365.75},
                   {material: "Roll Canvas", size: "36 x 48 inches",  price: 339.75},
                   {material: "Roll Canvas", size: "40 x 60 inches",  price: 460.75},
                  ],
    },

    {
        id: 55,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix3%206.jpg?alt=media&token=6944d8c4-cf52-480d-8af7-7047f6534151",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix3.jpg?alt=media&token=ef74b026-8fdb-461c-a38f-a4e17df92d71",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix3%205.jpg?alt=media&token=947633ec-7b93-4965-8cf4-0b191a7e45c8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix3%204.jpg?alt=media&token=17843523-f9dc-45c2-a5bb-a9983c5622ed",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix3%203.jpg?alt=media&token=283fcb67-7c34-41e8-8753-4b481fcb6dba",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20mix3%202.jpg?alt=media&token=16699bda-ec5c-4d04-b6b0-bf47bff0b88c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Mix Color",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Mix Color with Embedded Signature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "24 x 16 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "30 x 20 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "40 x 30 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "48 x 36 x 1.5D  inches",  price: 628.25},
                   {material: "Stretched Canvas", size: "60 x 28 x 1.5D  inches", price: 669.25},
                   {material: "Stretched Canvas", size: "60 x 40 x 1.5D  inches", price: 720.25},
                   {material: "Roll Canvas", size: "24 x 16  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "30 x 20 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "40 x 30 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "48 x 36 inches",  price: 335.75},
                   {material: "Roll Canvas", size: "60 x 28 inches",  price: 369.75},
                   {material: "Roll Canvas", size: "60 x 40 inches",  price: 460.75},
                  ],
    },
    {
        id: 56,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20Autumn%206.jpg?alt=media&token=1ba2ff73-9f1b-46b8-9d4f-0cf8be3c7f76",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20Autumn%205.jpg?alt=media&token=d2cd68f4-f569-4502-b34c-b9795a18ba71",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20Autumn.jpg?alt=media&token=37aa88a4-05eb-4a9a-938f-1b016802442a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20Autumn%204.jpg?alt=media&token=a4dc2f8e-c2ae-481e-8f66-0b9346b1638a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20Autumn%203.jpg?alt=media&token=7127fd3a-3067-41f8-b79d-bee72f9d5c60",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK_Abstract%20color%20Autumn%202.jpg?alt=media&token=1994dfc2-5f60-45ea-8214-c15020e3301b",
        ],
        title: "Abstract Mix Color Autumn",
        categ:"Abstract Color Mix",
        price: 149.99,
        desc: "Abstract Painting Pollock Style Golden Autumn Large Canvas Series with embedded signature, Home Wall Decor Art Collectibles. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "24 x 16 x 1.5D  inches",  price: 165.00},
                   {material: "Stretched Canvas", size: "30 x 20 x 1.5D  inches",  price: 232.25},
                   {material: "Stretched Canvas", size: "40 x 30 x 1.5D  inches",  price: 405.25},
                   {material: "Stretched Canvas", size: "48 x 36 x 1.5D  inches",  price: 628.25},
                   {material: "Stretched Canvas", size: "60 x 28 x 1.5D  inches", price: 669.25},
                   {material: "Stretched Canvas", size: "60 x 40 x 1.5D  inches", price: 720.25},
                   {material: "Roll Canvas", size: "24 x 16  inches",  price: 89.25},
                   {material: "Roll Canvas", size: "30 x 20 inches",  price: 122.75},
                   {material: "Roll Canvas", size: "40 x 30 inches",  price: 249.75},
                   {material: "Roll Canvas", size: "48 x 36 inches",  price: 335.75},
                   {material: "Roll Canvas", size: "60 x 28 inches",  price: 369.75},
                   {material: "Roll Canvas", size: "60 x 40 inches",  price: 460.75},
                  ],
    },
    
    {
        id: 57,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20snake%205.jpg?alt=media&token=d285674a-7f46-43cb-98e0-7b0debc107e3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20snake%202.jpg?alt=media&token=60819dab-26b9-4c66-9cb9-edd7a2d6a911",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20snake%203.jpg?alt=media&token=ea93bf50-72ac-46b2-b3e5-8395e16eb1ed",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%20snake.jpg?alt=media&token=bf70e2fa-ee20-46be-988e-af35aca9ba70",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20snake%204.jpg?alt=media&token=e3a903b1-5fdc-4ee2-a0da-f9454b35f58c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%20snake%20wm.jpg?alt=media&token=d2320b6a-7c1d-4ea9-966f-76eed9e49e8b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Snake Abstract Geometric",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature and animal. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 265.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 336.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 635.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 115.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 143.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 395.75},
                  ],
    },

    {
        id: 58,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin2%204.jpg?alt=media&token=bcb4e517-977d-4c4a-bf83-6dc663846704",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin2%205.jpg?alt=media&token=bddc206b-9290-4058-9254-3764513d36a8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin2%202.jpg?alt=media&token=cd537fcb-edab-409b-85b0-b35cf1949ac0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin2%201.jpg?alt=media&token=5b78d018-66cb-4424-8de6-f2e08181dd1f",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin2%203.jpg?alt=media&token=505c6a80-8a2e-4f2a-9844-2d069e69516c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%20seaurchin2%20wm.jpg?alt=media&token=ebb8887a-78d1-4e58-9535-062e1a5f1528",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Geometric",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature and animal. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 265.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 336.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 635.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 115.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 143.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 395.75},
                  ],
    },

    {
        id: 59,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin%205.jpg?alt=media&token=fa8aff73-dd25-42a1-9b2f-821f9849c0c1",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin%202.jpg?alt=media&token=824660d1-8228-41b3-b995-c56a70f77c00",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%20seaurchin%20wm.jpg?alt=media&token=465f54bc-3f0c-4689-ae9f-64f427335e22",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin%203.jpg?alt=media&token=b6985d4a-3bb9-42a0-aa65-10f6d10d17fe",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin%204.jpg?alt=media&token=c0fc0b51-08ea-4637-b90e-0be95a90f0e4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Seaurchin.jpg?alt=media&token=c8f463cc-b82b-4f8b-bbd9-36e0d2c03cfe",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Geometric",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature and animal. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 265.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 336.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 635.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 115.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 143.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 395.75},
                  ],
    },

    {
        id: 60,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Leopard%203.jpg?alt=media&token=c19db9cf-f711-45de-ae04-7d8fc466198c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Leopard%205.jpg?alt=media&token=c25ea602-cf48-4a93-b469-a48b2149a78f",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Leopard%202.jpg?alt=media&token=b1571d9c-e2eb-45ea-ae04-41c88ed235e2",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Leopard%204.jpg?alt=media&token=f118ff6b-b753-4d87-b178-9f03e1e97466",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20Leopard.jpg?alt=media&token=8ae5d28c-9da3-427b-8d19-286a4cf310d2",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%20Leopard%20wm.jpg?alt=media&token=fe1bbb8f-d44f-463a-8165-a3481b453d47",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Leopard Abstract Geometric",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature and animal. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 265.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 336.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 635.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 115.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 143.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 395.75},
                  ],
    },

    {
        id: 61,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20eye%204.jpg?alt=media&token=d04b8a1d-19c3-451f-bbc8-05b2eed5dcc8",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20eye%205.jpg?alt=media&token=418e6784-14e2-4b43-b469-3e1c65e6064e",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20eye%202.jpg?alt=media&token=0c426f88-6024-408d-807a-a8bff0eed3dc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20eye%203.jpg?alt=media&token=161f686d-ba1a-4dcb-b86b-1e96a17cda33",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMk%20geometric%20eye.jpg?alt=media&token=b1ac72df-9081-4909-9c69-49d847d2da55",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%20eye%20wm.jpg?alt=media&token=f662819c-e776-41e8-b813-ff6d84239d30",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5"
        ],
        title: "Abstract Geometric",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature and animal. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 265.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 336.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 635.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 115.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 143.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 395.75},
                  ],
    },

    {
        id: 62,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Alligator.jpg?alt=media&token=ca1a59bc-1b69-4e4d-855b-c7d96bf3b7ae",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Alligator%202.jpg?alt=media&token=0a052fc0-473f-4566-bfa9-6bbb7dcb6db3",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Alligator%204.jpg?alt=media&token=a419229a-036d-4d2a-9b39-b95ee8419f98",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Alligator%206.jpg?alt=media&token=130c4d5a-5974-4f46-9eeb-7245a2b92a53",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Alligator%203.jpg?alt=media&token=25ad2420-7858-4e54-b0d8-7aa477a1727b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%20Alligator%20wm.jpg?alt=media&token=bb1aa68a-e2e6-4b91-ba67-85c154af86ba",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FBorder%20Style%203.jpg?alt=media&token=01abb151-e5df-4391-a53d-3023373c49c5",
        ],
        title: "Alligator Abstract Geometric",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature and animal. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 265.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 336.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 635.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 115.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 143.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 395.75},
                  ],
    },

    {
        id: 63,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle2.jpg?alt=media&token=50c22398-9773-4af2-b2f5-cd1ec3ec3f8b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print.jpg?alt=media&token=ce9b69e5-f804-4478-bc62-042405201efa",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle.jpg?alt=media&token=d829e55d-3f84-4678-98a8-53735e6d967a",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle3.jpg?alt=media&token=bbfb5f0b-beca-4018-9c33-375cd63bb9ce",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle4.jpg?alt=media&token=f42961d3-67e2-465a-9694-296ac3c1ec7f",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle5.jpg?alt=media&token=1834ce08-98f0-4da6-b2a7-85324569432b",
        ],
        title: "Abstract Geometric Circle",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame,Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface.",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 208.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 265.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 498.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 82.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 105.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 305.75},
                   {material: "Fine Art Print", size: "30 x 12  inches",  price: 62.25},
                   {material: "Fine Art Print", size: "40 x 16 inches",  price: 98.75},
                   {material: "Fine Art Print", size: "60 x 24 inches",  price: 215.75},
                  ],
    },
    {
        id: 64,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20stripe%20circle2.jpg?alt=media&token=77c6459a-13a0-43b0-89cf-c64d10203c16",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%203.jpg?alt=media&token=e7b5f7ea-bed2-4e2e-beb6-d6b9b8efbd01",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20stripe%20circle.jpg?alt=media&token=14cbf531-d47b-4bc4-a5e7-4949d7433115",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20stripe%20circle3.jpg?alt=media&token=1aa79276-55ad-410c-ab52-62bbd1a868b0",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20stripe%20circle5.jpg?alt=media&token=2dd8b76f-0994-4ee4-a772-c11f4b60d822",
        ],
        title: "Abstract Geometric Circle",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame,Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface.",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 208.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 265.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 498.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 82.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 105.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 305.75},
                   {material: "Fine Art Print", size: "30 x 12  inches",  price: 62.25},
                   {material: "Fine Art Print", size: "40 x 16 inches",  price: 98.75},
                   {material: "Fine Art Print", size: "60 x 24 inches",  price: 215.75},
                  ],
    },

    {
        id: 65,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20vertex.jpg?alt=media&token=14afe922-65fa-40c7-9a9a-cdce64a423cc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20vertex2.jpg?alt=media&token=e88c32a6-6937-413d-a55b-90bb7a38409b",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20vertex3.jpg?alt=media&token=69bfe2e9-9684-49ff-b761-5478dc79ec60",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20vertex4.jpg?alt=media&token=300f78c9-5918-41fd-9136-8cee9e09ab51",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20vertex5.jpg?alt=media&token=3e1a2bff-f6c7-4680-a4d7-62a1e45585f6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20binary%20data%20vertex6.jpg?alt=media&token=d3f1ed42-0342-4d47-9ace-13ea6a0224e9"
        ],
        title: "Abstract Geometric Data Pattern",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This abstract geometric radial data pattern painting explores the idea of abstract and beautiful geometric pattern in data science. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame,Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface.",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 208.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 265.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 498.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 82.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 105.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 305.75},
                   {material: "Fine Art Print", size: "30 x 12  inches",  price: 62.25},
                   {material: "Fine Art Print", size: "40 x 16 inches",  price: 98.75},
                   {material: "Fine Art Print", size: "60 x 24 inches",  price: 215.75},
                  ],
    },
    {
        id: 66,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle%203.jpg?alt=media&token=9a7507b9-fa22-41ca-b203-bc9c8ea75d6d",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle%20s3%205.jpg?alt=media&token=18a85410-699e-497b-a2fd-f84188db690c",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle%203%202.jpg?alt=media&token=4d2fc907-8a13-4515-b0ae-a47f27b00380",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle%20s3%203.jpg?alt=media&token=857199d0-7e32-4691-aba4-85937edabfd6",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle%20s3%204.jpg?alt=media&token=dbaaa180-3dec-4ac6-b306-51e007bc7cfc",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FMK%20Geometric%20Abstract%20Circle%20s3%206.jpg?alt=media&token=6c9c8f29-8187-4db9-8840-dd17538abce4",
        "https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images08092022%2FGeometric%20Print%202.jpg?alt=media&token=5d56d5cd-d5e0-4bab-b2af-badcc9ffdb5d"
        ],
        title: "Abstract Geometric Circle",
        categ:"Abstract Geometric",
        price: 149.99,
        desc: "This large abstract geometric painting explores the idea of abstract and beautiful geometric pattern in nature and animal. Canvas: Beautiful Satin Canvas, OBA free, neutral white water resistant canvas composed of a luxurious poly/cotton blend giving it a uniform low glare finish. This canvas has excellent color brilliance and image definition and is ideal for art decor and bulk art reproductions. Canvas Stretched: The canvas print will be mounted over 1.5-inch thick (depth) wood frame stretcher bars and the image will wrap on the sides with a fraction of an inch around the back. Canvas UnStretched Roll: Canvas will be Rolled and shipped without a frame,Fine Art Print: This 245g, Bright White, textured water color fine art paper is made from poly cotton fiber highly durable and vibrant print surface.",
        variants: [{material: "Stretched Canvas", size: "30 x 12 x 1.5D  inches",  price: 208.00},
                   {material: "Stretched Canvas", size: "40 x 16 x 1.5D  inches",  price: 265.25},
                   {material: "Stretched Canvas", size: "60 x 24 x 1.5D  inches",  price: 498.25},
                   {material: "Roll Canvas", size: "30 x 12  inches",  price: 82.25},
                   {material: "Roll Canvas", size: "40 x 16 inches",  price: 105.75},
                   {material: "Roll Canvas", size: "60 x 24 inches",  price: 305.75},
                   {material: "Fine Art Print", size: "30 x 12  inches",  price: 62.25},
                   {material: "Fine Art Print", size: "40 x 16 inches",  price: 98.75},
                   {material: "Fine Art Print", size: "60 x 24 inches",  price: 215.75},
                  ],
    },

    {
        id: 67,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FHug_Brothers.jpg?alt=media&token=7b230469-19ea-4d3c-8ee8-c55d015f324a"],
        title: "Hug Brothers in War",
        categ:"Humanity",
        price: 3000.00,
        desc: "Original Painting: The teen found his little brother in debris during Syria Civil War, art collectible",
        variants: [{material: "Stretched Canvas", size: "18 x 24 x 0.75D  inches",  price: 3000.00}],
    },
    {
        id: 68,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FHug_Adopted%20Dog.jpg?alt=media&token=d04df67f-f8d8-4468-997e-18e6404e6d64"],
        title: "Hug Adopted Dog",
        categ:"Humanity",
        price: 1500.00,
        desc: "Original Painting: A stray dog has just been adopted, art collectible",
        variants: [{material: "Stretched Canvas", size: "12 x 12 x 0.75D  inches",  price: 1500.00}],
    },
    {
        id: 69,
        image: ["https://firebasestorage.googleapis.com/v0/b/challenge-bcf4d.appspot.com/o/images%2FHug_Kagaroo%20in%20Forest%20Fire2.jpg?alt=media&token=b0750c24-337f-463d-ac2d-6d9f8c876cac"],
        title: "Hug Kangaroos in a forest fire",
        categ:"Humanity",
        price: 3000.00,
        desc: "Orignal Painting: A Kangaroo hug her cub being frightened in a forest fire, art collectible",
        variants: [{material: "Stretched Canvas", size: "18 x 24 x 0.75D  inches",  price: 3000.00}],
    },

];