// import logo from './logo.svg';
import React, { useEffect } from 'react';
import './App.css';
import Header from './Header';
import Home from './Home';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Checkout from './Checkout';
import Login from './Login';
// import Payment from './Payment';
import Orders from './Orders';
import Success from './stripe-checkout/success';
import Canceled from './stripe-checkout/canceled';
import {auth} from './firebase';
import { useStateValue } from './StateProvider';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import Footer from './Footer';
import ShipPolicy from './ShipPolicy';
import TermUse from './TermUse';
import DetailProductPage from './DetailProductPage/DetailProductPage';
import ReactFirebaseFileUpload from './FirebaseUpload';

const promise=loadStripe('pk_live_51KMbNcJYJAYch09tbg1WippIhWMZt9ugNUSTZTUehxNfzlOreXqUTQK8a4cV54dEOR96vhhMPWsWE54WHZJbQCkQ00isP5MkPw');

function App() {
  const [{},dispatch]=useStateValue();

  useEffect(()=>{
    //will only run once when the app component loads..
    auth.onAuthStateChanged(authUser =>{
      console.log('The user is >>> ', authUser);
      if (authUser) {
            //the user just logged in/user was loggied in
          dispatch({
            type:'SET_USER',
            user:authUser
          });
      } else {
        //the user is logged out
        dispatch({
          type:'SET_USER',
          user:null
        })
      }
    });
  },[]);

  return (
    <Router>
    <div className='App'>
      <Switch>
      <Route path='/orders'>
        <Header />
        <Orders />
      </Route>

      <Route path='/login'>
        <Header />
        <Login />
        <Footer />
      </Route>

        <Route path='/fileupload'>
        <Header />
        <ReactFirebaseFileUpload />
      </Route>

      <Route path='/checkout'>
        <Header />
        <Elements stripe={promise}>
        <Checkout />
        </Elements>
      </Route>

      <Route path='/success'>
        <Header />
        <Success />
      </Route>

      <Route path='/canceled'>
        <Header />
        <Canceled />
      </Route>

      <Route path='/shippolicy'>
        <Header />
        <ShipPolicy />
        </Route>

        <Route path='/termuse'>
        z <Header />
          <TermUse />
        </Route>

        {/* <Route path='/payment'>
          <Header />
          <Elements stripe={promise}>
            <Payment />
          </Elements>
        </Route> */}

        <Route path='/product/:id'>
           <Header />
           <DetailProductPage />
           <Footer />
        </Route>

        <Route path='/'> 
          <Header />
          <Home />
          <Footer />
        </Route>
        </Switch>
    </div>
    </Router>
  );
}

export default App;
